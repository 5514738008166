<template>
    <div class="page">
        <Header />

        <h1>Need Tech Support?</h1>

        <router-link to="/Appointment" class = "button">Request An Appointment</router-link>
        <!-- <router-link to="/MyRepair" class = "button">Check My Repair</router-link> -->

        <h4>We offer a wide range of services to get your tech working right</h4>

        <div class = "container">
            <div class = "flex-container">
                <div class = "service-type-container">
                    <h1>Setup</h1>
                    <div class = "service" v-for="setup in setups" :key="setup.name">
                        <router-link :to="'/Appointment?type=' + setup.type + '&service=' + setup.serviceshortname + '&device=' + setup.device">{{setup.name}}</router-link>
                    </div>
                </div>
                <div class = "service-type-container">
                    <h1>Service</h1>
                    <div class = "service" v-for="service in services" :key="service.name">
                        <router-link :to="'/Appointment?type=' + service.type + '&service=' + service.serviceshortname + '&device=' + service.device">{{service.name}}</router-link>
                    </div>
                </div>
                <div class = "service-type-container">
                    <h1>Repair</h1>
                    <div class = "service" v-for="repair in repairs" :key="repair.name">
                        <router-link :to="'/Appointment?type=' + repair.type + '&service=' + repair.serviceshortname + '&device=' + repair.device">{{repair.name}}</router-link>
                    </div>
                </div>
            </div>

            <div class = "flex-container phone">
                <div class = "service-type-container">
                    <h1>Setup</h1>
                    <div class = "service" v-for="setup in setups" :key="setup.name">
                        <router-link :to="'/Appointment?type=' + setup.type + '&service=' + setup.serviceshortname + '&device=' + setup.device">{{setup.name}}</router-link>
                    </div>
                </div>
                <div class = "service-type-container">
                    <h1>Service</h1>
                    <div class = "service" v-for="service in services" :key="service.name">
                        <router-link :to="'/Appointment?type=' + service.type + '&service=' + service.serviceshortname + '&device=' + service.device">{{service.name}}</router-link>
                    </div>
                </div>
                <div class = "service-type-container">
                    <h1>Repair</h1>
                    <div class = "service" v-for="repair in repairs" :key="repair.name">
                        <router-link :to="'/Appointment?type=' + repair.type + '&service=' + repair.serviceshortname + '&device=' + repair.device">{{repair.name}}</router-link>
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </div>
</template>

<script>
import Header from '../components/Header.vue';
import Footer from '../components/Footer.vue';

export default {
    name: 'Services',
    components:{
        Header,
        Footer
    },
    data : function(){
        return{
            api : "https://danstechservices.us:3000",
            setups : [],
            services : [],
            repairs : []
        }
    },
    mounted : function(){
        fetch(this.api + "/api/getsetups", { method: "POST" })
        .then( res => res.json() )
        .then( res => this.setups = res.response );

        fetch(this.api + "/api/getservices", { method: "POST" })
        .then( res => res.json() )
        .then( res => this.services = res.response );

        fetch(this.api + "/api/getrepairs", { method: "POST" })
        .then( res => res.json() )
        .then( res => this.repairs = res.response );

        fetch(this.api + "/api/recordload", { method: "POST", headers : { userdata : 'services'} });
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page{
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background-color: #e6e6e6;
    position: absolute;
    width: 100%;
    min-height: 100%;
    left: 0;
    top: 0;
}

a{
    color: #2c3e50;
    text-decoration: none;
}

.container{
    margin-right: auto;
    margin-left: auto;
    max-width: 1160px;
    user-select: none;
}

.flex-container{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.flex-container.phone{
    display: none;
}

.service-type-container{
    border: 1px solid #000;
    width: 33%;
    border-radius: 7px;
}

.service{
    width: 100%;
    align-content: center;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    transition: ease-in-out 0.2s;
}

.service:hover{
    color: #448ed8;
}

.button{
    display: block;
    margin: 30px auto 30px auto;
    border-radius: 7px;
    padding: 20px;
    background-color: rgb(105, 236, 214);
    width: 20%;
    cursor: pointer;
    transition: ease-in-out 0.2s;
    font-weight: bold;
}

.button:hover{
    background-color: rgb(155, 187, 235);
}

.mobile-only{
    display: none;
}

@media only screen and (max-width: 1180px) {
    .flex-container{
        display: none;
    }

    .flex-container.phone{
        display: block;
        width: 80%;
        margin: auto;
    }

    .service-type-container{
        border: none;
        width: 100%;
    }

    .service{
        font-size: 24px;
        width: 80%;
        margin: 10px auto 0px auto;
        border: 1px solid #000;
        border-radius: 7px;
        padding: 20px;
        transition: ease-in-out 0.2s;
    }

    .button {
        width: 80%;
    }

    .mobile-only{
        display: block;
    }
}
</style>
